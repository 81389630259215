/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

require("hiq/dist/hiq.css");

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === "/payment/success") {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionID = urlParams.get('session_id')

    fetch("/.netlify/functions/retrieveSession", {
      method: "POST",
      body: sessionID,
    })
    .then(response => response.json())
    .then(resultData => {
      const fbPayload = {
        'event': 'purchase',
        'purchase_currency': resultData.currency,
        'purchase_value': resultData.value,
      }

      const gaPayload = {
        'event': 'purchase',
        'ecommerce': {
          'purchase': {
            'value': resultData.value,
            'currency': resultData.currency,
          }
        }
      }

      if (process.env.NODE_ENV === "development") {
        console.log("Tracking: Purchase")
        console.log(fbPayload)
        console.log(gaPayload)
      } else {
        dataLayer.push(fbPayload)
        dataLayer.push(gaPayload)
      }
    })
  }
}